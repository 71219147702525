import React from 'react';
import './assets/Locations.css';

class Locations extends React.Component {
    render() {
        let locations = [];
        if(this.props?.locations?.length > 0){
            this.props?.locations?.forEach(location => {
                const locality = location?.address?.locality;
                const locationItem = <li key={locality} className="location-item px-3 py-1 text-color6 poppins" style={{"cursor": "pointer"}} onClick={(e) => this.setLocation(location.id, true)}>{locality}</li>
                locations.push(locationItem);
            });
        }
        return (
            <ul className="dropdown-menu bg-color2">
                {locations}
            </ul>
        )
    }

    setLocation = (location, ignoreLoaded) => {
        if (this.props?.setLocation) {
            this.props?.setLocation(location, ignoreLoaded);
        }
    }
}

export default Locations;