import React from 'react';
import './assets/Addresses.css';

class Addresses extends React.Component {
    render() {
        let addresses = [];
        this.props?.addresses?.forEach(location => {
            if (location.status === 'ACTIVE') {
                addresses.push(
                    <div className="col-12 p-4" key={location?.address?.locality || location?.address?.locality}>
                        <p className="text-white m-0 poppins">
                            <h4>{location.name}</h4>
                            <a
                                href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                                    `${location?.address?.address_line_1 || location?.address?.addressLine1}, 
                                    ${location?.address?.locality || location?.address?.locality}, 
                                    ${location?.address?.administrative_district_level_1 || location?.address?.administrativeDistrictLevel1} 
                                    ${location?.address?.postal_code || location?.address?.postalCode}`
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-white"
                            >
                                {location?.address?.address_line_1 || location?.address?.addressLine1}, 
                                {location?.address?.locality || location?.address?.locality}, 
                                {location?.address?.administrative_district_level_1 || location?.address?.administrativeDistrictLevel1} 
                                {location?.address?.postal_code || location?.address?.postalCode}
                            </a>
                        </p>
                        <a
                            href={"tel:" + (location.phone_number || location.phoneNumber)}
                            className={'text-white m-0 poppins'}
                        >
                            <p>{location.phone_number || location.phoneNumber}</p>
                        </a>
                    </div>
                );
            }
        });

        return <div className="row">{addresses}</div>;
    }
}

export default Addresses;
