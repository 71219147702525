import React from 'react';
import axios from 'axios';
import data from '../assets/resources';
import './assets/Shop.css';
import CategoriesList from './components/categories-list/CategoriesList';
import Locations from './components/locations/Locations';
import Products from './components/products/Products';
import Cart from './components/cart/Cart';
import CategoriesListHorizontal from './components/categories-list-horizontal/CategoriesListHorizontal';
import ReactGA from "react-ga4";
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import loadingImage from './../assets/minys/images/loading.gif'
import { Link } from 'react-router-dom';
import validator from 'validator';
import withNavigation from '../withNavigation/withNavigation';

let loaded = false;

class Shop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: null,
            cartLength: 0,
            promotions: this?.props?.multimedia,
            createOrder: true,
            isLoggedIn: Boolean(localStorage.getItem('jwt')),
            email: "",
            password: "",
            errorMessage: ''
        };
    }

    componentDidMount() {
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        if (currentLocation && orderId) {
            this.setLocation(currentLocation);
            this.getCart(orderId);
        } else if (currentLocation && !orderId) {
            this.createOrderId(currentLocation);
        }
        document.title = `Shop | ${process.env.REACT_APP_NAME}`;
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
    }

    componentDidUpdate(prevProps) {

        const { location } = this.props;
        const prevLocation = prevProps?.location
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        if (prevLocation && location && prevProps?.location !== location) {
            this.setLocation(location);
            localStorage.setItem('locationId', location);
            this.createOrderId(location);
            this.setState({ promotions: this?.props?.multimedia })
        } else if (location === null) {
            if (location && orderId) {
                this.setLocation(currentLocation);
                this.getCart(orderId);
            }
        } else if (location === currentLocation && !loaded) {
            this.getCart(orderId);
            this.setState({ promotions: this?.props?.multimedia })
            loaded = true;
        } else if (!currentLocation && !orderId) {
            localStorage.setItem('locationId', location);
            this.createOrderId(location);
        } else if (!prevLocation && !location) {
            if (this.props.navigate) {
                this.props.navigate("/");
            } else {
                console.error('navigate is not available in props');
            }
        }
    }

    createOrderId = (location) => {
        const url = process.env.REACT_APP_API_URL + 'orders/';
        axios.post(url + 'create', {
            locationId: location,
            lineItems: [],
            calculationPhase: 'TOTAL_PHASE',
            state: 'OPEN'
        })
            .then(res => {
                if (res.data.name === 'Error') {
                    alert(res.data.message);
                } else {
                    localStorage.setItem('orderId', res?.data?.order?.id);
                    localStorage.setItem('orderType', (new URL(window.location.href))?.pathname === "/menu" ? "regular" : "catering");
                    this.setState({ cart: res?.data, cartLength: 0 });
                }
            }
            );
    }

    getCart = (orderId) => {
        if (orderId && orderId !== "undefined") {
            const url = process.env.REACT_APP_API_URL + 'orders/';
            axios.get(url + '?orderId=' + orderId).then(res => {
                if (!res?.data?.lineItems) {
                    let cart = { ...this.state?.cart }
                    cart.order = res?.data;
                    this.setState({ cart });
                } else if (res?.data?.tenders) {
                    const tenders = res?.data?.tenders;
                    if (tenders.length > 0) {
                        let shouldRedirect = false;
                        tenders.forEach((tender) => {
                            if (tender.type === 'CARD' && tender.cardDetails?.status === 'CAPTURED') {
                                shouldRedirect = true;
                            } else if (tender.type === 'WALLET') {
                                shouldRedirect = true;
                            }
                        });
                        if (shouldRedirect) {
                            // Clear the old localStorage data
                            for (let i = 0; i < localStorage.length; i++) {
                                const key = localStorage.key(i);
                                if (key !== 'jwt' && key !== 'squareId') {
                                    localStorage.removeItem(key);
                                }
                            }
                            if (this.props.navigate) {
                                this.props.navigate(`/order/${orderId}`);
                            } else {
                                console.error('navigate is not available in props');
                            }
                        } else {
                            let cart = { ...this.state?.cart }
                            cart.order = res?.data;
                            this.setState({ cart: cart, cartLength: Number(res?.data?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
                        }
                    }
                } else if (res?.data?.fulfillments?.length > 0) {
                    this.props.navigate("/pay");
                }
                else {
                    let cart = { ...this.state?.cart }
                    cart.order = res?.data;
                    this.setState({ cart: cart, cartLength: Number(res?.data?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
                }
            });
        }
        else{
            this.createOrderId(this.props.location);
        }
    }

    setLocation = (location, ignoreLoaded) => {
        localStorage.setItem('locationId', location);
        this.props.setLocation(location, ignoreLoaded);
    };

    addItemToCart = (state) => {
        if (
            ((new URL(window.location.href))?.pathname === "/menu" && localStorage.getItem("orderType") === "regular") ||
            ((new URL(window.location.href))?.pathname === "/catering" && localStorage.getItem("orderType") === "catering")
        ) {
            let cart;
            if (this.state?.cart?.order) {
                cart = this.state?.cart;
                if (!cart?.order?.lineItems) {
                    cart.order.lineItems = [];
                }
                cart?.order?.lineItems.push(state?.product_to_add);
                this.setState({ cart })
            }
            else {
                cart = { order: [state?.product_to_add], ...this.state?.cart };
            }
            axios.patch(
                process.env.REACT_APP_API_URL + 'orders/update', {
                "orderId": cart?.order?.id,
                "locationId": this.props.location,
                "lineItems": cart?.order?.lineItems,
                "calculationPhase": "TOTAL_PHASE",
                "state": "OPEN"
            }).then(res => {
                this.setState({
                    cart: res?.data,
                    cartLength: Number(res?.data?.order?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0))
                });
            });
        }
        else {
            var startNewCart = window.confirm("You already have a " + (localStorage.getItem("orderType")) + " cart, do you like to start a new " + (localStorage.getItem("orderType") === "regular" ? "catering" : "regular") + " cart? ")
            if (startNewCart) {
                //this.createOrderId()
                const url = process.env.REACT_APP_API_URL + 'orders/';
                axios.post(url + 'create', {
                    "locationId": this.props.location,
                    "lineItems": [],
                    "calculationPhase": "TOTAL_PHASE",
                    "state": "OPEN"
                }).then(res => {
                    localStorage.setItem('orderId', res?.data?.order?.id);
                    localStorage.setItem('orderType', (new URL(window.location.href))?.pathname === "/menu" ? "regular" : "catering");
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": res.data?.order?.id,
                        "locationId": this.props.location,
                        "lineItems": [state?.product_to_add],
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    }).then(res => {
                        this.setState({
                            cart: res?.data,
                            cartLength: Number(res?.data?.order?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0))
                        });
                    });
                });
            }
        }
    }

    setQuantity = (uid, quantity) => {
        this.state?.cart?.order?.lineItems?.forEach(item => {
            if (item.uid === uid) {
                item.quantity = quantity;
                if (quantity !== 0)
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.order?.id,
                        "locationId": this.props.location,
                        "lineItems": this.state?.cart?.order?.lineItems,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    }).then(res => {
                        localStorage.setItem('orderId', res?.data?.order?.id);
                        this.setState({ cart: res?.data, cartLength: Number(res?.data?.order?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
                    });
                else {
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.order?.id,
                        "deleteItem": [uid],
                        "locationId": this.props.location,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    }).then(res => {
                        localStorage.setItem('orderId', res?.data?.order?.id);
                        if (res?.data?.order?.lineItems)
                            this.setState({ cart: res?.data, cartLength: Number(res?.data?.order?.lineItems?.reduce((total, item) => total + Number(item.quantity), 0)) });
                        else
                            this.setState({ cart: res?.data, cartLength: 0 });
                    });
                }
            }
        });
    }

    handleSignOut = (e) => {
        e.preventDefault();
        localStorage.removeItem('jwt');
        localStorage.removeItem('squareId');
        this.setState({ isLoggedIn: false });
        window.location.reload();
    };

    proceedToCheckout = (e) => {
        e.preventDefault()
        if (this.props.navigate) {
            this.props.navigate(this.state?.cart?.order?.fulfillments ? "/pay" : "/checkout");
        } else {
            console.error('navigate is not available in props');
        }
    }

    login = (e) => {
        e.preventDefault();
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errorMessage: '',
        });
    };

    validateForm = () => {
        const { email, password } = this.state;
        const errors = [];

        if (!validator.isEmail(email)) {
            errors.push('Invalid email address');
        }

        if (!password) {
            errors.push('Passwords cannot be empty');
        }

        if (errors.length > 0) {
            this.setState({ errorMessage: errors.join(', ') });
            return false;
        }

        return true;
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        var button_modal = document.getElementById("close-modal-button");

        if (!this.validateForm()) {
            return;
        }

        const { email, password } = this.state;
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + 'customer/login', {
                email,
                password
            });
            localStorage.setItem("jwt", response?.data?.jwt);
            localStorage.setItem("squareId", response?.data?.user?.squareId);
            if (this.props.navigate) {
                button_modal.click();
                this.props.navigate(this.state?.cart?.order?.fulfillments ? "/pay" : "/checkout");
            } else {
                console.error('navigate is not available in props');
            }
        } catch (error) {
            console.error('Login failed:', error.response.data);
            this.setState({ errorMessage: error.response.data.message || 'Login failed' });
        }
    };

    render() {
        if(!this.state.location && localStorage.getItem("locationId")) this.setLocation(localStorage.getItem("locationId"));
        if (!this.props?.catalog?.length) {
            return (
                <LoadingScreen imageSource={loadingImage} />
            )
        }

        if (!this.state.cart) {
            this.getCart(localStorage.getItem("orderId"));
        }

        let promos = [];
        this.state?.promotions?.forEach((promo, index) => {
            promos.push(
                <div className={index === 0 ? "carousel-item active" : "carousel-item"} key={"promo_" + index}>
                    <img src={promo} className="d-block w-100" alt="Promo" />
                </div>
            )
        })

        return (
            <div className='shop bg-color2'>
                <div className='corner-logo position-absolute d-none d-md-block'>
                    <a href="/">
                        <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                </div>
                <div className="sticky-top d-md-none">
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-6 text-end">
                            <div className='header'>
                                <div className="row">
                                    <div className="col-md-4 offset-md-6 col-3 p-3">
                                        <div className="dropdown bg-color2 rounded-pill">
                                            <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={data.LocationIcon} alt="" />
                                                <strong className='text-color6 ms-3'>{this?.props?.locations.find(location => location.id === this?.props?.location).address?.locality}</strong>
                                            </button>
                                            <Locations locations={this.props.locations} setLocation={this.setLocation} />
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-3 p-3">
                                        <button className="btn bg-color2 rounded-pill" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCart" aria-expanded="false" aria-controls="collapseCart" disabled={this.state?.cartLength <= 0}>
                                            <img src={data.CartIcon} alt="" />
                                            <span className="badge rounded-circle bg-color4 text-color6 position-absolute mt-3">
                                                {this.state?.cartLength}
                                            </span>
                                        </button>
                                        <Cart cart={this.state?.cart} setQuantity={this.setQuantity} proced={this.state?.cartLength <= 0} />
                                    </div>
                                    <div className="col-3 d-md-none p-3">
                                        <div className="dropdown bg-color2 rounded-pill">
                                            <button className="btn dropdown-toggle fw-semibold text-color6 poppins" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Categories
                                            </button>
                                            <CategoriesListHorizontal catalog={this.props.catalog} />
                                        </div>
                                    </div>
                                    <div className='col-3 d-md-none p-3'>
                                        <div className="dropdown bg-color2 rounded-pill">
                                            <button
                                                className="btn dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="32"
                                                    height="32"
                                                    fill="currentColor"
                                                    className="bi bi-person"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                                </svg>
                                            </button>
                                            <ul className="dropdown-menu bg-color2">
                                                {!this.props?.user && <li className="px-3 py-1 text-color3 poppins">
                                                    <a className="text-decoration-none" href="/login">Login</a>
                                                </li>}
                                                {!this.props?.user && <li className="px-3 py-1 text-color3 poppins">
                                                    <a className="text-decoration-none" href="/signup">Register</a>
                                                </li>}
                                                {this.props?.user && <li className="px-3 py-1 text-color3 poppins">
                                                    <a className="text-decoration-none" href="/profile">Profile</a>
                                                </li>}
                                                {this.props?.user && <li className="px-3 py-1 text-color3 poppins">
                                                    <a className="text-decoration-none" href="/" onClick={(e) => {
                                                        for (let i = 0; i < localStorage.length; i++) {
                                                            const key = localStorage.key(i);
                                                            if (key === 'jwt' || key === 'squareId') {
                                                                localStorage.removeItem(key);
                                                            }
                                                        }
                                                        window.location.reload();
                                                    }
                                                    }>Sign out</a>
                                                </li>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='header sticky-top d-none d-md-block'>
                    <div className="row">
                        <div className="col-md-2 offset-md-8 col-6 p-3">
                            <div className="dropdown bg-color2 rounded-pill">
                                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={data.LocationIcon} alt="" />
                                    <strong className='text-color6 ms-3'>{this.props.locations.find(location => location.id === this.props.location).address.locality}</strong>
                                </button>
                                <Locations locations={this.props.locations} setLocation={this.setLocation} />
                            </div>
                        </div>
                        <div className="col-md-1 col-3 p-3">
                            <button className="btn bg-color2 rounded-pill" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCart" aria-expanded="false" aria-controls="collapseCart" disabled={(this.state?.cartLength <= 0/*1 || !this.props?.open*/)}>
                                <img src={data.CartIcon} alt="" />
                                <span className="badge rounded-circle bg-color4 text-color6 position-absolute mt-3">
                                    {this.state?.cartLength}
                                </span>
                            </button>
                            <Cart cart={this.state?.cart} user={this.props.user} setQuantity={this.setQuantity} proced={this.state?.cartLength <= 0} />
                        </div>
                        <div className="col-md-1 col-6 p-3">
                            {/* <Link className="text-color6 text-decoration-none" to={(new URL(window.location.href))?.pathname === "/menu" ? "/catering" : "/menu"}>
                                <span className='poppins fw-bold'>
                                {
                                    (new URL(window.location.href))?.pathname === "/menu" ? 
                                    "Order Catering" : "Main Menu"
                                }
                                </span>
                            </Link> */}
                            <div className="dropdown bg-color2 rounded-pill">
                                <button
                                    className="btn dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        fill="currentColor"
                                        className="bi bi-person"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                    </svg>
                                </button>
                                <ul className="dropdown-menu bg-color2">
                                    {!this.state.isLoggedIn && (
                                        <>
                                            <li className="px-3 py-1 text-color3 poppins">
                                                <a className="text-decoration-none" href="/login">Login</a>
                                            </li>
                                            <li className="px-3 py-1 text-color3 poppins">
                                                <a className="text-decoration-none" href="/signup">Register</a>
                                            </li>
                                        </>
                                    )}
                                    {this.state.isLoggedIn && (
                                        <>
                                            <li className="px-3 py-1 text-color3 poppins">
                                                <a className="text-decoration-none" href="/profile">Profile</a>
                                            </li>
                                            <li className="px-3 py-1 text-color3 poppins">
                                                <a
                                                    className="text-decoration-none"
                                                    href="/"
                                                    onClick={(e) => this.handleSignOut(e)}
                                                >
                                                    Sign out
                                                </a>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="col-3 d-md-none p-3">
                            <div className="dropdown bg-color2 rounded-pill">
                                <button className="btn dropdown-toggle fw-semibold text-color6 poppins" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Categories
                                </button>
                                <CategoriesListHorizontal catalog={this.props.catalog} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-color2 pt-5">
                    {/*<div className='shop-image d-none d-md-block'></div>*/}
                    <div className="carousel-wrapper">
                        <div className="carousel" data-ride="carousel" data-interval="5000">
                            <div className="carousel-inner">
                                {promos}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target=".carousel" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target=".carousel" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>


                    <div className="row">
                        <Link to="/" className='poppins text-color6 fst-italic text-end' onClick={(e) => {
                            // Clear the old localStorage data
                            for (let i = 0; i < localStorage.length; i++) {
                                const key = localStorage.key(i);
                                if (key !== 'jwt' && key !== 'squareId' && key !== 'locationId') {
                                    localStorage.removeItem(key);
                                }
                            }
                        }} >
                            <img src={data.Back} alt={'Back to home'} />
                            <small>Start new order</small>
                        </Link>
                        {
                            (new URL(window.location.href))?.pathname === "/menu" ?
                                <React.Fragment>
                                    <div className="col-md-2 d-none d-md-block">
                                        <CategoriesList catalog={this.props.catalog} />
                                    </div>
                                    <div className="col-md-10 col-12 p-5">
                                        <Products location={this.props.location} open={this.props.open} catalog={this.props.catalog} cart={this.state.cart} addItemToCart={this.addItemToCart} />
                                    </div>
                                </React.Fragment> :
                                <React.Fragment>
                                    <div className="col-md-12 col-12 p-5">
                                        <Products location={this.props.location} open={this.props.open} catalog={this.props.catalog} cart={this.state.cart} addItemToCart={this.addItemToCart} />
                                    </div>
                                </React.Fragment>
                        }

                    </div>
                </div>
                <div className="modal fade" id="authModal" tabIndex="-1" aria-labelledby="authModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body bg-color3 rounded text-center">
                                <img className='img-fluid my-5 mx-auto' src={data.logo_login} alt="Miny's Mexican Restaurant" />
                                <form onSubmit={this.handleSubmit} className='p-5'>
                                    <p className='text-danger'>{this.state.errorMessage}</p>
                                    <div className='form-group m-2'>
                                        <label className='text-color1 fw-bold'>Email:</label><br />
                                        <input
                                            type="email"
                                            className="w-100 my-2 bg-color2"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            required
                                        />
                                        {!validator.isEmail(this.state.email) && (
                                            <div className="error">Invalid email address</div>
                                        )}
                                    </div>
                                    <br />

                                    <div className='form-group m-2'>
                                        <label className='text-color1 fw-bold'>Password:</label><br />
                                        <input
                                            type="password"
                                            className="w-100 my-2 bg-color2"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                    <br />

                                    <button id='close-modal-button' data-bs-dismiss="modal" hidden>Close modal</button>
                                    <button onClick={e => this.handleSubmit(e)} className='btn bg-color4 poppins w-100 fw-semibold text-color6 mb-3'>Login</button>
                                    <button data-bs-dismiss="modal" onClick={e => this.proceedToCheckout(e)} className='btn bg-color4 poppins w-100 fw-semibold text-color6'>Continue as a guest</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withNavigation(Shop);
