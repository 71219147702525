import React from 'react';
import axios from 'axios';
import validator from 'validator';
import './assets/ForgotPassword.css'; // Use the same CSS file or create a separate one if needed
import ReactGA from 'react-ga4';
import data from '../assets/resources';
import withNavigation from '../withNavigation/withNavigation';

class ForgotPassword extends React.Component {
    componentDidMount() {
        document.title = `Reset Password | ${process.env.REACT_APP_NAME}`;
        document.querySelector('meta[name="description"]').setAttribute("content", process.env.REACT_APP_DESCRIPTION);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
    }

    state = {
        email: '',
        successMessage: '',
        errorMessage: '',
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errorMessage: '',
            successMessage: '',
        });
    };

    validateForm = () => {
        const { email } = this.state;
        const errors = [];

        if (!validator.isEmail(email)) {
            errors.push('Invalid email address');
        }

        if (errors.length > 0) {
            this.setState({ errorMessage: errors.join(', ') });
            return false;
        }

        return true;
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (!this.validateForm()) {
            return;
        }

        const { email } = this.state;

        try {
            // Send the email to the backend for password reset
            await axios.post(process.env.REACT_APP_API_URL + 'customer/forgot-password', {
                email
            });
            // Handle the response from the backend as needed
            this.setState({ successMessage: 'If an account with that email exists, you will receive a password reset link shortly.' });
        } catch (error) {
            // Handle errors
            console.error('Password reset failed:', error.response.data);
            this.setState({ errorMessage: error.response.data.message || 'Password reset failed' });
        }
    };

    render() {
        const { email, successMessage, errorMessage } = this.state;

        return (
            <div className='login bg-color2'>
                <div className='corner-logo position-absolute d-none d-md-block'>
                    <a href="/">
                        <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                </div>
                <div className='text-center p-5'>
                    <div className='row'>
                        <div className='col-10 offset-1 col-md-4 offset-md-4 bg-color3 rounded'>
                            <img className='img-fluid my-5' src={data.logo_login} alt="Miny's Mexican Restaurant" />
                            <form onSubmit={this.handleSubmit} className='p-5'>
                                <div className='form-group m-2'>
                                    <label className='text-color1 fw-bold'>Email:</label><br />
                                    <input
                                        type="email"
                                        className="w-100 my-2 bg-color2"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {!validator.isEmail(email) && (
                                        <div className="error">Invalid email address</div>
                                    )}
                                </div>
                                <br />

                                <button type="submit" className='btn bg-color4 poppins w-20 fw-semibold text-color6'>Reset Password</button>
                            </form>
                            {successMessage && <p className="success-message">{successMessage}</p>}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNavigation(ForgotPassword);
