import React from 'react';

class GoogleMapsPlacesScript extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapsLoaded: false,
      autocomplete: null,
    };
  }

  componentDidMount() {
    this.loadGoogleMapsScript();
  }

  loadGoogleMapsScript = () => {
    const scriptAlreadyAdded = Array.from(document.head.getElementsByTagName('script')).some(script => {
      return script.src.includes('maps.googleapis.com/maps/api/js');
    });

    if (!scriptAlreadyAdded) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
      script.async = true;
      script.onload = () => {
        this.setState({ mapsLoaded: true }, this.initMap);
      };
      document.head.appendChild(script);
    } else {
      this.setState({ mapsLoaded: true }, this.initMap);
    }
  };

  initMap = () => {
    const form = document.getElementById("address-form-input");
    const autocomplete = new window.google.maps.places.Autocomplete(form, { types: ['geocode'] });
    this.setState({ autocomplete });
    autocomplete.addListener("place_changed", this.handlePlaceChanged);
  };

  handlePlaceChanged = () => {
    // Handle place changed event
  };

  render() {
    return null;
  }
}

export default GoogleMapsPlacesScript;
