import React from "react";
import { NumericFormat } from 'react-number-format';
import './assets/Products.css';
//import Add from './../../assets/images/add.png';
import data from '../../../assets/resources';
import Modal from "../modal/Modal";

class Products extends React.Component {
    
    category(category) {
        let products = [];
        category?.items?.forEach(item => {
            let max_price = 0;
            let min_price = null;
            let price;
            item.itemData.variations.forEach(variation => {
                // Get default price in cents, convert to dollars
                const defaultPriceStr = variation?.itemVariationData?.priceMoney?.amount;
                const defaultPrice = defaultPriceStr !== null ? parseInt(defaultPriceStr) : null; // Convert to dollars
            
                // Initialize the location price to null
                let locationPrice = null;
            
                // Check for location-specific overrides
                if (variation?.itemVariationData?.locationOverrides && variation?.itemVariationData?.locationOverrides.length > 0) {
                    const locationOverride = variation.itemVariationData.locationOverrides.find(
                        l => l.locationId === this.props.location
                    );
            
                    if (locationOverride && locationOverride?.priceMoney?.amount) {
                        locationPrice = locationOverride?.priceMoney?.amount;
                    }
                }
            
                // Convert locationPrice to float (dollars)
                
                const locationPriceInt = locationPrice !== null ? parseInt(locationPrice) : null;
            
                // Determine the price to use
                const priceToCompare = locationPriceInt !== null ? locationPriceInt : defaultPrice;
            
                if (priceToCompare !== null) {
                    // Update max_price
                    if (priceToCompare > max_price) max_price = priceToCompare;
            
                    // Update min_price
                    if (min_price === null || priceToCompare < min_price) min_price = priceToCompare;
                }
            
                // Optional: Log for debugging
                if (item.itemData.name === 'Water') {
                    // console.log(`Processing variation: ${variation.itemVariationData.name}, Price: ${defaultPrice}`);
                    // console.log(`Processing variation: ${variation.itemVariationData.name}, Price: ${locationPriceInt}`);
                    // console.log(`Processing variation: ${variation.itemVariationData.name}, Price: ${priceToCompare}`);
                    // console.log(`Current min_price: ${min_price}, Current max_price: ${max_price}`);
                }
            });
            
            

            if (max_price === min_price) {
                price = (
                    <p className='text-dark item-info poppins'>
                        <NumericFormat 
                            value={max_price / 100} 
                            displayType={'text'} 
                            thousandSeparator={true} 
                            prefix={'$'} 
                            fixedDecimalScale={true} 
                            decimalScale={2} 
                        />
                    </p>
                );
            } else {
                price = (
                    <p className='text-dark item-info poppins'>
                        <NumericFormat 
                            value={min_price / 100} 
                            displayType={'text'} 
                            thousandSeparator={true} 
                            prefix={'$'} 
                            fixedDecimalScale={true} 
                            decimalScale={2} 
                        /> 
                        - 
                        <NumericFormat 
                            value={max_price / 100} 
                            displayType={'text'} 
                            thousandSeparator={true} 
                            prefix={'$'} 
                            fixedDecimalScale={true} 
                            decimalScale={2} 
                        />
                    </p>
                );
            }

            products.push(
                <div className="col-md-3 col-6" key={item.itemData.name}>
                    <div className="container m-2 p-2 bg-color3 add-button" data-bs-toggle="modal" data-bs-target={"#modal"+item.id}>
                        {/*<div className='position-absolute z-n1'>
                            <img src={Add} className='img-fluid add-button' alt={'add item'}/>
                        </div>*/}
                        <div className="mx-auto" >
                            <img src={item.itemData.imageDetails[0]?.url ? item.itemData.imageDetails[0]?.url : data.defaultLogo} className='img-fluid img-thumbnail rounded' alt="" />
                        </div>
                        <p className='text-color6 item-name poppins mt-1 mb-0'><strong>{item.itemData.name}</strong></p>
                        {price}
                    </div>
                    <Modal open={this.props?.open} product={item} cart={this.props?.cart} addItemToCart={this.addItemToCart} location={this.props?.location}/>
                </div>
            );
        });
        return (
            <React.Fragment key={category?.categoryData?.name}>
                <h3 id={category.id} className='poppins text-color6 ms-2'><strong>{category?.categoryData?.name}</strong></h3>
                <div className="row">
                    {products}
                </div>
            </React.Fragment>
        );
    }
    
    render() {
        let categories = [];
        this.props?.catalog?.forEach(category => categories.push(this.category(category)));
        return (<React.Fragment>{categories}</React.Fragment>);
    }

    addItemToCart = (state) => this.props?.addItemToCart(state);

}

export default Products;
