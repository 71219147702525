import React from "react";
import "./assets/PaymentForm.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSafari } from "react-device-detect";
import withNavigation from '../../../withNavigation/withNavigation';

class PaymentForm extends React.Component {
  timerID;
  cashAppPay;
  googlePay;
  applePay;

  state = {
    appId:
      process.env.REACT_APP_PROD === "true"
        ? process.env.REACT_APP_APPLICATION_ID
        : process.env.REACT_APP_APPLICATION_ID_DEV,
    locationId: this.props?.location,
    edit: false,
    tip: 0,
    newCard: this.props?.cards?.length > 0 ? false : true,
    selectedCard: null
  };

  componentDidUpdate(prevState) {
    if (prevState.tip !== this.props.tip) {
      this.setState({ tip: this.props?.tip });
      this.handlePaymentMethods();
    }
  }

  render() {
    let _cards = [];
    this.props?.cards?.forEach(card => {
      _cards.push(<option key={"card_" + card.id} value={card.id}>{card?.card_brand + " - " + card?.exp_month + "/" + card?.exp_year + " - XXXX-XXXX-XXXX-" + card?.last_4}</option>)
    })
    return (
      <div className="wrapper mb-2">
        {this.contactDetails()}
        {this.props?.cart?.fulfillments[0]?.deliveryDetails && (
          <p className="poppins text-color6" style={{ fontStyle: "italic" }}>
            The gratuity is directed to the delivery driver. According to
            DoorDash, in cases of non-tipped orders, there may be a potential
            for extended delivery times.
          </p>
        )}
        <hr />
        {this.props?.attributes?.pay_cashapp ||
          this.props?.attributes?.pay_gpay ||
          (isSafari && this.props?.attributes?.pay_apple_pay) ? (
          <div className="row">
            <h3 className="poppins text-color6">Express Payment</h3>
            {isSafari && this.props?.attributes?.pay_apple_pay ? (
              <div className="col-md-4 p-2">
                <form id="apple-pay-payment-form">
                  <div id="apple-pay-button"></div>
                </form>
                <div id="payment-status-container"></div>
              </div>
            ) : (
              <div></div>
            )}
            {this.props?.attributes?.pay_gpay ? (
              <div className="col-md-4 col-md-offset-1 p-2">
                <form id="google-payment-form">
                  <div id="google-pay-button"></div>
                </form>
                <div id="google-payment-status-container"></div>
              </div>
            ) : (
              <div></div>
            )}
            {this.props?.attributes?.pay_cashapp ? (
              <div className="col-md-4 col-md-offset-1 p-2">
                <form id="cash-app-payment-form">
                  <div id="cash-app-pay"></div>
                </form>
                <div id="cash-app-payment-status-container"></div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div></div>
        )}
        {/*isSafari ? (
              <div className="row">
                <h3 className="poppins text-color6">Express Payment</h3>
                <div className="col-md-4 p-2">
                  <form id="apple-pay-payment-form">
                    <div id="apple-pay-button"></div>
                  </form>
                  <div id="payment-status-container"></div>
                </div>
                <div className="col-md-4 col-md-offset-1 p-2">
                  <form id="google-payment-form">
                    <div id="google-pay-button"></div>
                  </form>
                  <div id="google-payment-status-container"></div>
                </div>
                <div className="col-md-4 col-md-offset-1 p-2">
                  <form id="cash-app-payment-form">
                    <div id="cash-app-pay"></div>
                  </form>
                  <div id="cash-app-payment-status-container"></div>
                </div>
              </div>
            ) : (
                <div className="row">
                <h3 className="poppins text-color6">Express Payment</h3>
                <div className="col-md-4 col-md-offset-1 p-2">
                  <form id="google-payment-form">
                    <div id="google-pay-button"></div>
                  </form>
                  <div id="google-payment-status-container"></div>
                </div>
                <div className="col-md-4 col-md-offset-1 p-2">
                  <form id="cash-app-payment-form">
                    <div id="cash-app-pay"></div>
                  </form>
                  <div id="cash-app-payment-status-container"></div>
                  
                </div>
              </div>
            )*/}
        <div className="row border rounded mt-5">
          <h3 className="poppins text-color6">Credit Card</h3>
          <small className="text-muted">
            All transactions are secure and encrypted by Square Inc
          </small>
          <div className="mt-2">
            {(this.props.cards && this.props.cards?.length > 0) && (
              <div className="row">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.setState({ newCard: false })}
                  className={!this.state.newCard ? "bg-color6 poppins text-color3 col-6" : "poppins text-color6 col-6"}
                >
                  Select saved card
                </div>

                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.setState({ newCard: true, selectedCard: null })}
                  className={this.state.newCard ? "bg-color6 poppins text-color3 col-6" : "poppins text-color6 col-6"}
                >
                  New Card
                </div>
              </div>
            )}
            <form id="payment-form mt-2">
              <div
                className="mt-3"
                id="card-container"
                hidden={!this.state?.newCard}
              ></div>
              <select onChange={(e) => this.selectCard(e)} className="w-100 my-2 mt-3" hidden={this.state?.newCard}>
                <option disabled selected>
                  Select a card
                </option>
                {_cards}
              </select>
              <button
                className="btn poppins bg-color4 w-100 text-color6"
                id="card-button"
                type="button"
              >
                Pay with card
              </button>
            </form>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            limit={2}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="colored"
          />
        </div>
        <hr />
        <span className="poppins text-color6">
          By placing an order with {process.env.REACT_APP_NAME}, customers agree
          to the <a href="/terms-and-conditions">Terms and Conditions</a>
        </span>
      </div>
    );
  }

  selectCard(e) {
    const selectedCard = this.props?.cards?.find(c => c.id === e.target.value);
    this.setState({ selectedCard });
  }

  contactDetails() {
    if (this.props?.cart?.fulfillments) {
      if (
        this.props?.cart?.fulfillments[0]?.pickupDetails &&
        !this.props?.cart?.fulfillments[0]?.deliveryDetails
      ) {
        return (
          <div>
            <p className="poppins text-color6">
              <strong>Name: </strong>
              {
                this.props?.cart?.fulfillments[0]?.pickupDetails?.recipient
                  ?.displayName
              }
              <br />
              <strong>Email: </strong>
              {
                this.props?.cart?.fulfillments[0]?.pickupDetails?.recipient
                  ?.emailAddress
              }
              <br />
              <strong>Phone number: </strong>
              {
                this.props?.cart?.fulfillments[0]?.pickupDetails?.recipient
                  ?.phoneNumber
              }
              <br />
              <hr />
              <strong>Order Type: </strong>
              {this.props?.cart?.fulfillments[0]?.pickupDetails?.scheduleType}
              <br />
              <strong>Pickup At: </strong>
              {new Date(
                this.props?.cart?.fulfillments[0]?.pickupDetails?.pickupAt
              ).toLocaleString("en-US", {
                dateStyle: "medium",
                timeStyle: "short",
                //timeZone: 'America/New_York', // Replace with the desired timezone
              })}
              <br />
            </p>
            {/* <button className='btn bg-color6 poppins text-white' onClick={(e) => this.setState({ edit: true })}>Edit Pick up details</button> */}
          </div>
        );
      } else {
        return (
          <div>
            <p className="poppins text-color6">
              <strong>Name: </strong>
              {
                this.props?.cart?.fulfillments[0]?.deliveryDetails?.recipient
                  ?.displayName
              }
              <br />
              <strong>Email: </strong>
              {
                this.props?.cart?.fulfillments[0]?.deliveryDetails?.recipient
                  ?.emailAddress
              }
              <br />
              <strong>Phone number: </strong>
              {
                this.props?.cart?.fulfillments[0]?.deliveryDetails?.recipient
                  ?.phoneNumber
              }
              <br />
              <hr />
              <strong>Delivery by: </strong>
              {
                this.props?.cart?.fulfillments[0]?.deliveryDetails
                  ?.courierProviderName
              }
              <br />
              <strong>Dropoff Address: </strong>
              {
                this.props?.cart?.fulfillments[0]?.deliveryDetails?.recipient
                  ?.address?.addressLine1
              }
              ,{" "}
              {
                this.props?.cart?.fulfillments[0]?.deliveryDetails?.recipient
                  ?.address?.administrativeDistrictLevel1
              }
              <br />
              <strong>Dropoff Notes: </strong>
              {this.props?.cart?.fulfillments[0]?.deliveryDetails?.dropoffNotes}
              <br />
              <strong>Estimated delivery: </strong>
              {new Date(
                this.props?.cart?.fulfillments[0]?.deliveryDetails?.deliverAt
              ).toLocaleString("en-US", {
                dateStyle: "medium",
                timeStyle: "short",
              })}
              <br />
            </p>
            {/* <button className='btn bg-color6 poppins text-white' onClick={(e) => this.setState({ edit: true })}>Edit Pick up details</button> */}
          </div>
        );
      }
    } else return <br />;
  }

  displayPaymentResults(response, toastr) {
    if (
      response?.status &&
      (response?.status === "COMPLETED" || response?.status === "APPROVED")
    ) {
      toast.update(toastr, {
        render: "Payment succesful",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      // Clear the old localStorage data
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key !== 'jwt' && key !== 'squareId') {
          localStorage.removeItem(key);
        }
      }
      this.props.navigate(`/order/${this.props?.cart?.id}`);
    } else {
      let error_message;
      if (response?.errors) {
        if (response?.errors[0]?.code === "CVV_FAILURE") {
          error_message = "Incorrect CVV";
        } else if (
          response?.errors[0]?.code === "ADDRESS_VERIFICATION_FAILURE"
        ) {
          error_message = "Incorrect postal code";
        } else if (response?.errors[0]?.code === "INVALID_EXPIRATION") {
          error_message = "Incorrect expiration date";
        } else if (response?.errors[0]?.code === "GENERIC_DECLINE") {
          error_message = "Card declined";
        } else {
          error_message = "Card declined";
        }
      }
      toast.update(toastr, {
        render: error_message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }

  addPickupDetails = (state) => {
    this.props?.addPickupDetails(state);
    this.setState({ edit: false });
  };

  // async componentDidMount() {
  //     if(!this.props?.cart?.lineItems){
  //       window.location.reload(true);
  //     }
  //     if (this?.props?.cart?.tenders) {
  //         let shouldRedirect = false;
  //         for (const tender of this.props.cart.tenders) {
  //           if (tender.type === 'WALLET' || tender.cardDetails?.status === 'CAPTURED') {
  //             shouldRedirect = true;
  //             break;
  //           }
  //         }
  //         if (shouldRedirect) {
  //           localStorage.clear();
  //           window.location.href = `/order/${this.props?.cart?.id}`;
  //         }
  //       }

  //     if (!window.Square) {
  //         throw new Error('Square.js failed to load properly');
  //     }
  //     const payments = window.Square.payments(this.state.appId, this.state.locationId);

  //     let card;
  //     try {
  //         card = await this.initializeCard(payments);
  //     } catch (e) {
  //         console.error('Initializing Card failed', e);
  //         return;
  //     }

  //     const cardButton = document.getElementById('card-button');
  //     cardButton.addEventListener('click', async (event) => {
  //         event.preventDefault();
  //         cardButton.disabled = true;
  //         const toastr = toast.loading("Processing...",{autoClose: 5000});
  //         const token = await this.tokenize(card, toastr);
  //         try {
  //             const response = await axios.post(process.env.REACT_APP_API_URL + 'payment/create', {
  //               orderId: this.props?.cart?.id,
  //               locationId: this.props?.cart?.locationId,
  //               token,
  //               tipAmount: this.props?.tip
  //             });

  //             // console.log(response);)
  //             if(response.data?.errors){
  //                 toast.update(toastr, { render: response.data.errors[0]?.detail, type: "error", isLoading: false, autoClose: 5000 });
  //                 if(response.data.errors[0]?.detail === 'The order is already paid.'){
  //                     setTimeout(() => {
  //                         window.location.href = `/order/${this.props?.cart?.id}`;
  //                     }, 2000);
  //                     cardButton.disabled = true;
  //                 } else {
  //                     cardButton.disabled = false;
  //                 }
  //             } else {
  //                 this.displayPaymentResults(response.data, toastr);
  //                 cardButton.disabled = false;
  //             }
  //           } catch (error) {
  //             if (error.response) {
  //               // The request was made, but the server responded with an error
  //               if(error?.response?.data?.errors){
  //                 error?.response?.data?.errors.forEach(error => {
  //                   // console.log(error.code);
  //                   // console.log(error.detail);
  //                   if(error.detail === 'The order is already paid.'){
  //                     toast.update(toastr, { render: `${error.detail} Redirecting to Order Detail`, type: "error", isLoading: false, autoClose: 5000 });
  //                     setTimeout(() => {
  //                       window.location.href = `/order/${this.props?.cart?.id}`;
  //                   }, 3000);
  //                   }
  //                 });
  //               }
  //               // You can then use `errorMessage` and `errorCode` to display or handle the error in your component
  //             } else if (error.request) {
  //               // The request was made, but there was no response
  //               console.error('No Response:', error.request);
  //             } else {
  //               // Something happened in setting up the request that triggered an error
  //               console.error('Error:', error.message);
  //             }
  //             console.error(error);
  //             // toast.update(toastr, { render: error.errors[0].detail, type: "error", isLoading: false, autoClose: 5000 });
  //           }

  //     });

  //     this.mountPaymentButtons(payments);
  // }

  async componentDidMount() {
    if (!this.props?.cart?.lineItems) {
      window.location.reload(true);
    }

    if (this?.props?.cart?.tenders) {
      let shouldRedirect = false;
      for (const tender of this.props.cart.tenders) {
        if (
          tender.type === "WALLET" ||
          tender.cardDetails?.status === "CAPTURED"
        ) {
          shouldRedirect = true;
          break;
        }
      }
      if (shouldRedirect) {
        // Clear the old localStorage data
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (key !== 'jwt' && key !== 'squareId') {
            localStorage.removeItem(key);
          }
        }
        this.props.navigate(`/order/${this.props?.cart?.id}`);
        return; // Return to prevent further execution
      }
    }

    // Load Square.js script asynchronously if it's not already loaded
    if (!window.Square) {
      const script = document.createElement("script");
      script.src =
        process.env.REACT_APP_PROD === "false"
          ? `https://sandbox.web.squarecdn.com/v1/square.js`
          : `https://web.squarecdn.com/v1/square.js`;

      script.defer = true;
      script.async = true;
      document.head.appendChild(script);

      // Wait for the script to be loaded before continuing
      await new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
      });

      // Check again if Square.js is loaded
      if (!window.Square) {
        throw new Error("Square.js failed to load properly");
      }
    }

    // Proceed with Square initialization
    const payments = window.Square.payments(
      this.state.appId,
      this.state.locationId
    );

    let card;
    try {
      card = await this.initializeCard(payments);
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }

    const cardButton = document.getElementById("card-button");
    cardButton.addEventListener("click", async (event) => {
      event.preventDefault();
      cardButton.disabled = true;
      const toastr = toast.loading("Processing...", { autoClose: 5000 });
      let token;
      let squareId = null;
      if (!this.state?.selectedCard) token = await this.tokenize(card, toastr);
      else {
        token = this.state?.selectedCard?.id;
        squareId = this.state?.selectedCard?.customer_id;
      }
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "payment/create",
          {
            orderId: this.props?.cart?.id,
            locationId: this.props?.cart?.locationId,
            token,
            squareId,
            tipAmount: this.props?.tip,
          }
        );
        // if(response?.data === 'ERR_BAD_RESPONSE'){
        //   this.displayPaymentResults(response.data, toastr);
        //   cardButton.disabled = false;
        // }
        if (response.data?.errors) {
          toast.update(toastr, {
            render: response.data.errors[0]?.detail,
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
          if (
            response.data.errors[0]?.detail === "The order is already paid."
          ) {
            setTimeout(() => {
              this.props.navigate(`/order/${this.props?.cart?.id}`);
            }, 2000);
            cardButton.disabled = true;
          } else {
            cardButton.disabled = false;
          }
        } else {
          this.displayPaymentResults(response.data, toastr);
          cardButton.disabled = false;
        }
      } catch (error) {
        if (error.response) {
          // The request was made, but the server responded with an error
          if (error?.response?.data?.errors) {
            error?.response?.data?.errors.forEach((error) => {
              // console.log(error.code);
              // console.log(error.detail);
              if (error.detail === "The order is already paid.") {
                toast.update(toastr, {
                  render: `${error.detail} Redirecting to Order Detail`,
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                });
                setTimeout(() => {
                  this.props.navigate(`/order/${this.props?.cart?.id}`);
                }, 3000);
              } else if(error.code === 'CARD_EXPIRED'){
                toast.update(toastr, {
                  render: error.detail,
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                });
                cardButton.disabled = false;
              }
            });
          }
          // You can then use `errorMessage` and `errorCode` to display or handle the error in your component
        } else if (error.request) {
          // The request was made, but there was no response
          console.error("No Response:", error.request);
        } else {
          // Something happened in setting up the request that triggered an error
          console.error("Error:", error.code);
        }
        toast.update(toastr, { render: error.errors[0].detail, type: "error", isLoading: false, autoClose: 5000 });
      }
    });

    this.mountPaymentButtons(payments);
  }

  async initializeCard(payments) {
    const card = await payments.card();
    await card.attach("#card-container");
    return card;
  }

  async tokenize(paymentMethod, toastr) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      toast.update(toastr, {
        render: "No card",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }

  /* CASH APP FUNCTIONS */
  buildPaymentRequest(payments) {
    const amount =
      (parseInt(this.props?.cart?.totalMoney.amount) +
        parseInt(this.props?.tip)) /
      100;
    const paymentRequest = payments.paymentRequest({
      countryCode: "US",
      currencyCode: "USD",
      total: {
        amount: amount.toString(),
        label: "Total",
      },
    });
    return paymentRequest;
  }

  async initializeCashApp(payments) {
    const paymentRequest = this.buildPaymentRequest(payments);
    const cashAppPay = await payments.cashAppPay(paymentRequest, {
      redirectURL: `${process.env.REACT_APP_WEBSITE}/order/${this.props?.cart?.id}`,
      referenceId: process.env.REACT_APP_NAME,
    });
    await cashAppPay.attach("#cash-app-pay");
    return cashAppPay;
  }

  /* GOOGLE FUNCTIONS */

  async initializeGooglePay(payments) {
    const paymentRequest = this.buildPaymentRequest(payments);
    const googlePay = await payments.googlePay(paymentRequest);
    await googlePay.attach("#google-pay-button", {
      buttonColor: "black",
      buttonType: "short",
    });
    return googlePay;
  }

  /* APPLE PAY FUNCTIONS */
  async initializeApplePay(payments) {
    const paymentRequest = this.buildPaymentRequest(payments);
    const applePay = await payments.applePay(paymentRequest);
    // Note: You do not need to `attach` applePay.
    return applePay;
  }
  async handlePaymentMethods() {
    try {
      if (!window.Square) {
        throw new Error("Square.js failed to load properly");
      }

      // Initialize Square payments
      const payments = window.Square.payments(
        this.state.appId,
        this.state.locationId
      );

      // Destroy existing payment methods if they exist
      if (this.googlePay) {
        this.googlePay.destroy();
      }
      if (this.cashAppPay) {
        try {
          this.cashAppPay.destroy();
        } catch (error) {
          // Catch and ignore the "nothing to destroy" error for Cash App
          // It may indicate that the payment method was not properly initialized
          console.log("[Cash App Pay]", error.message);
        }
      }
      if (isSafari && this.applePay) {
        this.applePay.destroy();
      }

      // Wait a short period to ensure proper cleanup
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Mount payment buttons with the newly initialized payments object
      this.mountPaymentButtons(payments);
    } catch (error) {
      console.log(error); // Log the error for debugging
    }
  }

  async mountPaymentButtons(payments) {
    // INITIALIZE CASHAPP
    try {
      this.cashAppPay = await this.initializeCashApp(payments);
    } catch (e) {
      console.error("Initializing Cash App Pay failed", e);
    }

    this.cashAppPay.addEventListener("ontokenization", async (event) => {
      const { tokenResult, error } = event.detail;
      if (error) {
        // developer handles error
        console.log(error);
      } else if (tokenResult.status === "OK") {
        const toastr = toast;
        // developer passes token to backend for use with CreatePayment
        try {
          await axios
            .post(process.env.REACT_APP_API_URL + "payment/create", {
              orderId: this.props?.cart?.id,
              locationId: this.props?.cart?.locationId,
              token: tokenResult.token,
              tipAmount: this.props?.tip,
            })
            .then((res) => {
              if (res.data?.errors) {
                toast.update(toastr, {
                  render: res.data.errors[0]?.detail,
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                });
                if (
                  res.data.errors[0]?.detail === "The order is already paid."
                ) {
                  setTimeout(() => {
                    this.props.navigate(`/order/${this.props?.cart?.id}`);
                  }, 2000);
                }
              } else {
                this.displayPaymentResults(res.data, toastr);
              }
            });
        } catch (error) {
          if (error?.response?.data?.errors) {
            error?.response?.data?.errors.forEach((error) => {
              // console.log(error.code);
              // console.log(error.detail);
              if (error.detail === "The order is already paid.") {
                toast.update(toastr, {
                  render: error.detail,
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                });
                setTimeout(() => {
                  this.props.navigate(`/order/${this.props?.cart?.id}`);
                }, 3000);
              }
            });
          }
        }
      }
    });

    // GOOGLE PAY
    try {
      if (this.props?.attributes?.pay_gpay) {
        this.googlePay = await this.initializeGooglePay(payments);
      }
    } catch (e) {
      console.error("Initializing Google Pay failed", e);
    }
    if (this.googlePay !== undefined) {
      const googlePayButton = document.getElementById("google-pay-button");
      googlePayButton.addEventListener("click", async (event) => {
        event.preventDefault();
        const toastr = toast;
        const token = await this.tokenize(this.googlePay);
        try {
          await axios
            .post(process.env.REACT_APP_API_URL + "payment/create", {
              orderId: this.props?.cart?.id,
              locationId: this.props?.cart?.locationId,
              token,
              tipAmount: this.props?.tip,
            })
            .then((res) => {
              if (res.data?.errors) {
                toast.update(toastr, {
                  render: res.data.errors[0]?.detail,
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                });
                if (
                  res.data.errors[0]?.detail === "The order is already paid."
                ) {
                  setTimeout(() => {
                    this.props.navigate(`/order/${this.props?.cart?.id}`);
                  }, 2000);
                }
              } else {
                this.displayPaymentResults(res.data, toastr);
              }
            });
        } catch (error) {
          if (error?.response?.data?.errors) {
            error?.response?.data?.errors.forEach((error) => {
              // console.log(error.code);
              // console.log(error.detail);
              if (error.detail === "The order is already paid.") {
                toast.update(toastr, {
                  render: error.detail,
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                });
                setTimeout(() => {
                  this.props.navigate(`/order/${this.props?.cart?.id}`);
                }, 3000);
              }
            });
          }
        }
      });
    }

    // APPLE PAY
    if (isSafari) {
      try {
        this.applePay = await this.initializeApplePay(payments);
      } catch (e) {
        console.error("Initializing Apple Pay failed", e);
      }
      if (this.applePay !== undefined) {
        const applePayButton = document.getElementById("apple-pay-button");
        applePayButton.addEventListener("click", async (event) => {
          event.preventDefault();
          const toastr = toast;
          const token = await this.tokenize(this.applePay);
          try {
            await axios
              .post(process.env.REACT_APP_API_URL + "payment/create", {
                orderId: this.props?.cart?.id,
                locationId: this.props?.cart?.locationId,
                token,
                tipAmount: this.props?.tip,
              })
              .then((res) => {
                if (res.data?.errors) {
                  toast.update(toastr, {
                    render: res.data.errors[0]?.detail,
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                  });
                  if (
                    res.data.errors[0]?.detail === "The order is already paid."
                  ) {
                    setTimeout(() => {
                      this.props.navigate(`/order/${this.props?.cart?.id}`);
                    }, 2000);
                  }
                } else {
                  this.displayPaymentResults(res.data, toastr);
                }
              });
          } catch (error) {
            if (error?.response?.data?.errors) {
              error?.response?.data?.errors.forEach((error) => {
                // console.log(error.code);
                // console.log(error.detail);
                if (error.detail === "The order is already paid.") {
                  toast.update(toastr, {
                    render: error.detail,
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                  });
                  setTimeout(() => {
                    this.props.navigate(`/order/${this.props?.cart?.id}`);
                  }, 3000);
                }
              });
            }
          }
        });
      }
    }
  }
}

export default withNavigation(PaymentForm);
