import React from 'react';
import axios from 'axios';
import validator from 'validator';
import './assets/Register.css';
import ReactGA from 'react-ga4';
import data from '../assets/resources';
import withNavigation from '../withNavigation/withNavigation';

class Register extends React.Component {
    componentDidMount() {
        document.title = `Register | ${process.env.REACT_APP_NAME}`;
        document.querySelector('meta[name="description"]').setAttribute("content", process.env.REACT_APP_DESCRIPTION);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
        this.getProfile();
    }

    async getProfile() {
        const jwt = localStorage.getItem("jwt");
        if (jwt) {
            const response = await axios.post(process.env.REACT_APP_API_URL + "customer/get-user-details", { jwt });
            if (response) this.props.navigate("/");
        }
    }

    state = {
        name: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        errorMessage: '',
        passwordRequirements: {
            length: false,
            specialChar: false,
            number: false,
            uppercase: false,
        },
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'password') {
            this.checkPasswordRequirements(value);
        }

        this.setState({
            [name]: value,
            errorMessage: '',
        });
    };

    checkPasswordRequirements = (password) => {
        this.setState({
            passwordRequirements: {
                length: password.length >= 8,
                specialChar: /[!@#$%^&*]/.test(password),
                number: /\d/.test(password),
                uppercase: /[A-Z]/.test(password),
            },
        });
    };

    validateForm = () => {
        const { name, lastName, email, phone, password, confirmPassword, passwordRequirements } = this.state;
        const errors = [];

        if (validator.isEmpty(name)) {
            errors.push('First name cannot be empty');
        }

        if (validator.isEmpty(lastName)) {
            errors.push('Last name cannot be empty');
        }

        if (!validator.isEmail(email)) {
            errors.push('Invalid email address');
        }

        if (!validator.isMobilePhone(phone, 'any', { strictMode: false })) {
            errors.push('Invalid phone number');
        }

        if (password !== confirmPassword) {
            errors.push('Passwords do not match');
        }

        // Check if all password requirements are met
        if (!passwordRequirements.length || !passwordRequirements.specialChar || !passwordRequirements.number || !passwordRequirements.uppercase) {
            errors.push('Password does not meet the complexity requirements');
        }

        if (errors.length > 0) {
            this.setState({ errorMessage: errors.join(', ') });
            return false;
        }

        return true;
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (!this.validateForm()) {
            return;
        }

        const { name, lastName, email, phone, password, confirmPassword } = this.state;

        try {
            // Send registration data to the backend
            await axios.post(process.env.REACT_APP_API_URL + 'customer/register', {
                name,
                lastName,
                email,
                phone,
                password,
                confirmPassword,
            });

            alert(`Awesome! ${name}, you're almost done! Check your email for a verification link to complete your registration.`);

            this.props.navigate("/");
        } catch (error) {
            this.setState({ errorMessage: error.response.data.message || 'Registration failed' });
        }
    };

    render() {
        const {
            name,
            lastName,
            email,
            phone,
            password,
            confirmPassword,
            errorMessage,
            passwordRequirements,
        } = this.state;

        return (
            <div className='login bg-color2'>
                <div className='corner-logo position-absolute d-none d-md-block'>
                    <a href="/">
                        <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                </div>
                <div className='text-center p-5'>
                    <div className='row'>
                        <div className='col-10 offset-1 col-md-4 offset-md-4 bg-color3 rounded'>
                            <img className='img-fluid my-5' src={data.logo_signup} alt="Miny's Mexican Restaurant" />
                            {errorMessage && <p className='text-danger mt-3'>{errorMessage}</p>}
                            <form onSubmit={this.handleSubmit} className='p-5'>
                                <div className='form-group m-2'>
                                    <label className='text-color1 fw-bold'>First Name:</label><br />
                                    <input
                                        type="text"
                                        className="w-100 my-2 bg-color2"
                                        name="name"
                                        value={name}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </div>
                                <br />
                                <div className='form-group m-2'>
                                    <label className='text-color1 fw-bold'>Last Name:</label><br />
                                    <input
                                        type="text"
                                        className="w-100 my-2 bg-color2"
                                        name="lastName"
                                        value={lastName}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </div>
                                <br />
                                <div className='form-group m-2'>
                                    <label className='text-color1 fw-bold'>Email:</label><br />
                                    <input
                                        type="email"
                                        className="w-100 my-2 bg-color2"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </div>
                                <br />
                                <div className='form-group m-2'>
                                    <label className='text-color1 fw-bold'>Phone Number:</label><br />
                                    <input
                                        type="tel"
                                        className="w-100 my-2 bg-color2"
                                        name="phone"
                                        value={phone}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </div>
                                <br />
                                <div className='form-group m-2'>
                                    <label className='text-color1 fw-bold'>Password:</label><br />
                                    <input
                                        type="password"
                                        className="w-100 my-2 bg-color2"
                                        name="password"
                                        value={password}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    <div className="password-requirements">
                                        <p className={passwordRequirements.length ? 'valid' : 'invalid'}>
                                            {passwordRequirements.length ? '✔' : '✖'} At least 8 characters
                                        </p>
                                        <p className={passwordRequirements.specialChar ? 'valid' : 'invalid'}>
                                            {passwordRequirements.specialChar ? '✔' : '✖'} At least 1 special symbol (!@#$%^&*)
                                        </p>
                                        <p className={passwordRequirements.number ? 'valid' : 'invalid'}>
                                            {passwordRequirements.number ? '✔' : '✖'} At least 1 number
                                        </p>
                                        <p className={passwordRequirements.uppercase ? 'valid' : 'invalid'}>
                                            {passwordRequirements.uppercase ? '✔' : '✖'} At least 1 uppercase letter
                                        </p>
                                    </div>

                                </div>
                                <br />
                                <div className='form-group m-2'>
                                    <label className='text-color1 fw-bold'>Confirm Password:</label><br />
                                    <input
                                        type="password"
                                        className="w-100 my-2 bg-color2"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {password !== confirmPassword && (
                                        <div className="error">Passwords do not match</div>
                                    )}
                                </div>
                                <br />
                                <button type="submit" className='btn bg-color4 poppins w-20 fw-semibold text-color6'>Register</button>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withNavigation(Register);
