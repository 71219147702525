/* eslint-disable no-unused-expressions */
import React from 'react';
import { NumericFormat } from 'react-number-format';
import './assets/CartDescription.css';
import data from '../../assets/resources';
import CheckoutModal from '../checkout-modal/CheckoutModal';
import { v4 as uuidv4 } from 'uuid';

class CartDescription extends React.Component {

    render() {
        const actualPage = window.location.href.split("/")[3];
        if (this.props?.items?.lineItems) {
            const tip = this.props?.tip || (this.props?.items?.tenders && this.props?.items?.tenders[0]?.tipMoney?.amount);
            if (this.props?.items?.lineItems?.length !== 0) {
                var items = [];
                var modals = [];
                this.props?.items?.lineItems?.forEach(item => {
                    items.push(this.item(item));
                    modals.push(this.modal(item))
                });

                return (
                    <div>
                        <table className="table table-hover poppins text-color6 items-description">
                            <thead>
                                <tr>
                                    <th scope="col">Item</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Subtotal</th>
                                    <th scope="col">Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                                {/* <tr >
                                    <td colSpan={3}>Tax</td>
                                    <td><NumericFormat value={this.props?.items?.totalTaxMoney?.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></td>
                                </tr> */}
                                <tr >
                                    <td colSpan={3}>Online fee</td>
                                    <td><NumericFormat value={this.props?.items?.serviceCharges[0]?.totalMoney?.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></td>
                                </tr>
                                {
                                    this.props?.items?.serviceCharges[1] &&
                                    <tr >
                                        <td colSpan={3}>Delivery fee</td>
                                        <td><NumericFormat value={this.props?.items?.serviceCharges[1]?.totalMoney?.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></td>
                                    </tr>
                                }
                                {
                                    (tip && tip !== '0') &&
                                    <tr >
                                        <td colSpan={3}>Tip</td>
                                        <td><NumericFormat value={tip / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></td>
                                    </tr>
                                }
                                {
                                    (this.props?.items?.discounts) &&
                                    <tr >
                                        <td colSpan={3}>{this.props?.items?.discounts[0].name}</td>
                                        <td><NumericFormat value={this.props?.items?.discounts[0].amountMoney.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'-$'} fixedDecimalScale={true} decimalScale={2} /></td>
                                    </tr>
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th scope="col" colSpan={3}>Total</th>
                                    <th scope="col">
                                        <NumericFormat 
                                            value={(actualPage === 'checkout' || actualPage === 'pay') ? (parseFloat(this.props?.items?.totalMoney?.amount) + parseFloat(this.props?.tip)) / 100 : (parseFloat(this.props?.items?.tenders[0].amountMoney?.amount)) / 100}
                                            displayType={'text'} thousandSeparator={true}
                                            prefix={'$'}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                        />
                                        <br />
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                        {modals}
                    </div>
                );
            }
            else {
                return (
                    <div className="cart-description">
                        Your cart is empty
                    </div>
                );
            }
        }
        else {
            return (
                <div className="cart-description">
                    Your cart is empty
                </div>
            );
        }
    }

    item(item) {
        var list_item = <div></div>;
        if (this.props?.catalog) {
            this.props?.catalog?.forEach(category => {
                category?.items?.forEach(category_item => {
                    category_item?.itemData?.variations?.forEach(variation => {
                        if (variation.id === item.catalogObjectId) {
                            var modifiers = [];
                            if (item.modifiers)
                                item.modifiers.forEach(modifier => {
                                    if (modifier.totalPriceMoney.amount !== "0")
                                        modifiers.push(
                                            <div key={`${uuidv4()}-${modifier.catalogObjectId}`}>
                                                <small className='fw-light'>+<NumericFormat value={modifier.totalPriceMoney.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /> {modifier.name}</small>
                                            </div>
                                        )
                                    else
                                        modifiers.push(
                                            <div key={`${uuidv4()}-${modifier.catalogObjectId}`}>
                                                <small className='fw-light'>{modifier.name}</small>
                                            </div>
                                        )
                                });
                            list_item = (
                                <tr key={`${uuidv4()}-${item.uid}`}>
                                    <td>
                                        {item.name} <br />
                                        {item?.variationName && (<small className='fw-light'>{item?.variationName}</small>)} <br />
                                        {modifiers}
                                    </td>
                                    <td>
                                        {this.props.edit && <span className='quantity-button'>
                                            <img onClick={(e) => this.setQuantity(item.uid, (parseInt(item.quantity) - 1))} src={data.ReduceCart} alt="" />
                                        </span>}
                                        <span className='fw-semibold text-color6 mx-3'>{item.quantity}</span>
                                        {this.props.edit && <span className='quantity-button'>
                                            <img onClick={(e) => this.setQuantity(item.uid, (parseInt(item.quantity) + 1))} src={data.AddCart} alt="" />
                                        </span>}
                                    </td>
                                    <td><NumericFormat value={(item.basePriceMoney.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></td>
                                    {/* <td><NumericFormat value={(item.totalTaxMoney.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></td> */}
                                    <td><NumericFormat value={(item.totalMoney.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></td>
                                    {this.props.edit && <td>
                                        {/* <button type="button" className="btn" data-bs-toggle="modal" data-bs-target={"#checkoutModal" + item.uid}>
                                            <img src={data.Edit} alt="" />
                                        </button> */}
                                        <button type="button" className="btn">
                                            <img onClick={(e) => this.setQuantity(item.uid, 0)} src={data.Trash} alt="" />
                                        </button>
                                    </td>}
                                    {/*<CheckoutModal item={item} product={category_item} updateItem={this.updateItem}/>*/}
                                </tr>
                            )
                        }
                    })
                })
            })
        }
        else {
            var modifiers = [];
            if (item.modifiers)
                item.modifiers.forEach(modifier => {
                    if (modifier.totalPriceMoney.amount !== "0")
                        modifiers.push(
                            <div key={`${uuidv4}-${modifier.catalogObjectId}`}>
                                <small className='fw-light'>+<NumericFormat value={modifier.totalPriceMoney.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /> {modifier.name}</small>
                            </div>
                        )
                    else
                        modifiers.push(
                            <div key={`${uuidv4}-${modifier.catalogObjectId}`}>
                                <small className='fw-light'>{modifier.name}</small>
                            </div>
                        )
                });
            list_item = (
                <tr key={`${uuidv4()}-${item.uid}`} className="bg-color3">
                    <td>
                        {item.name} <br />
                        {/* <NumericFormat value={(item.basePriceMoney.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /> */}
                        {modifiers}
                    </td>
                    <td>
                        <span className='fw-semibold text-color6 mx-3'>{item.quantity}</span>
                    </td>
                    {/* {console.log(item)} */}
                    <td><NumericFormat value={(item.basePriceMoney.amount / 100)*item.quantity} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></td>
                    <td><NumericFormat value={(item.totalMoney.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></td>
                </tr>
            )
        }

        return list_item;
    }

    modal(item) {
        var list_modal = <div></div>;
        this.props?.catalog?.forEach(category => {
            category?.items?.forEach(category_item => {
                category_item?.itemData?.variations?.forEach(variation => {
                    if (variation.id === item.catalogObjectId) {
                        list_modal = (
                            <div key={`${uuidv4()}-${item.uid}`} >
                                <CheckoutModal item={item} product={category_item} updateItem={this.updateItem} />
                            </div>
                        );
                    }
                });
            });
        });
        return list_modal;
    }

    setQuantity = (uid, quantity) => this.props?.setQuantity(uid, quantity);
    updateItem = (product_to_update) => this.props?.updateItem(product_to_update);
}

export default CartDescription;