import React from 'react';
import { NumericFormat } from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';
import './assets/Modal.css';
import data from '../../../assets/resources';

class Modal extends React.Component {

  state = {
    total: (this.props?.product?.itemData?.variations?.length > 1) ? 0 : this.locationPrice(this.props?.product?.itemData?.variations),
    modifiers_total: 0,
    product_to_add: {
      "catalogObjectId": this.props?.product?.itemData?.variations[0]?.id,
      "quantity": 1,
      "modifiers": [],
      "notes": ""
    },
    variations_required: (this.props?.product?.itemData?.variations?.length > 1),
    modifiers_checked: {},
    variations_checked: {},
    required_modifiers: this.checkModifiers()
  }

  checkModifiers() {
    var required = [];
    if (this.props?.product?.itemData?.modifierListInfo) {
      this.props?.product?.itemData?.modifierListInfo.forEach(modifier => {
        if (modifier.details?.selectionType === "SINGLE") {
          required.push(modifier.modifierListId);
        }
      });
    }
    return required;
  }

  locationPrice(variations) {
    return variations.map((variation) => {
      // Default price
      let price = variation.itemVariationData.priceMoney.amount;
  
      // Check for location-specific overrides
      if (variation.itemVariationData.locationOverrides && variation.itemVariationData.locationOverrides.length > 0) {
        const locationOverride = variation.itemVariationData.locationOverrides.find(
          l => l.locationId === this.props?.location
        );
  
        if (locationOverride) {
          price = locationOverride?.priceMoney?.amount;
        }
      }
      return price;
    });
  }
  
  render() {
    var modifiers = [];
    if (this.props?.product?.itemData?.modifierListInfo)
      this.props?.product?.itemData?.modifierListInfo?.forEach(modifier => modifiers.push(this.modifier(modifier)));

    var button;
    if (this.props.open) {
      if (this.state?.variations_required) {
        button = <button className='btn w-100 bg-color4 text-color6 poppins fw-semibold' data-bs-dismiss="modal" onClick={this.addItemToCart} disabled>Add item to cart</button>
      }
      else {
        button = <button className='btn w-100 bg-color4 text-color6 poppins fw-semibold' data-bs-dismiss="modal" onClick={this.addItemToCart}>Add item to cart</button>
        this.state.required_modifiers?.forEach(modifier => {
          var m = this.state.product_to_add?.modifiers?.find(mod => mod.modifierListId === modifier);
          if (!m) button = <button className='btn w-100 bg-color4 text-color6 poppins fw-semibold' data-bs-dismiss="modal" onClick={this.addItemToCart} disabled>Add item to cart</button>;
        })
      }
    }
    else {
      button = <button className='btn w-100 bg-color4 text-color6 poppins fw-semibold' data-bs-dismiss="modal" disabled>The restaurant is currently closed</button>
    }
    return (
      <div className="modal fade" id={"modal" + this.props?.product?.id} tabIndex="-1" aria-labelledby="productModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className='bg-color2'>
              <div className="text-end">
                <button data-bs-toggle="collapse" data-bs-dismiss="modal" className='btn text-color6'>
                  <img src={data.X} alt="" />
                </button>
              </div>
              <div className="image-background" style={{ height: '280px' }}>
                <img className='img-fluid rounded' src={this.props?.product?.itemData?.imageDetails[0]?.url ? this.props?.product?.itemData?.imageDetails[0]?.url : data.defaultLogo} alt="" />
              </div>
              <h3 className='text-color6 poppins fw-bold m-3'>{this.props?.product?.itemData?.name}</h3>
              <h5 className='text-color6 poppins fw-bold mx-3'><NumericFormat value={(parseInt(this.state?.total) + this.state?.modifiers_total) * parseInt(this.state?.product_to_add?.quantity) / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></h5>
              <p className='text-color6 poppins mx-3'><small>{this.props?.product?.itemData?.description}</small></p>
              {this.variations(this.props?.product?.itemData?.variations)}
              {modifiers}
              {/*<div className='notes-section'>
                                    <div className="bg-color7 p-3 text-color6 poppins fw-semibold mt-3">
                                        Notes
                                    </div>
                                    <div className='options px-3 pt-2'>
                                        <textarea name="notes" id="" rows="3" className='notes-input bg-color2 text-color6 w-100' placeholder="Type note" onChange={(e) => this.setNotes(e)}></textarea>
                                    </div>
                                </div>*/}
            </div>
            <div className='row m-2'>
              <div className="col-4">
                <span>
                  <img className='quantity-button' onClick={(e) => this.setQuantity(this.state?.product_to_add?.quantity - 1)} src={data.ReduceCart} alt="" />
                </span>
                <span className='fw-semibold text-color6 mx-3'>{this.state?.product_to_add?.quantity}</span>
                <span>
                  <img className='quantity-button' onClick={(e) => this.setQuantity(this.state?.product_to_add?.quantity + 1)} src={data.AddCart} alt="" />
                </span>
              </div>
              <div className="col-8">
                {button}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  modifier(modifier) {
    var inputs = [];
    modifier.details.modifiers.forEach(option => {
      var op;
      const modifierId = `${this.state.product_to_add.catalogObjectId}-${option.id}`;

      if (modifier.details.selectionType === 'SINGLE') {
        op = (
          <input
            type='radio'
            key={uuidv4()}
            id={modifierId}
            name={modifierId}
            checked={this.state.modifiers_checked[option.id]}
            onChange={(e) => this.setModifier(e, option, 'single')}
          />
        );
      } else if (modifier.details.selectionType === 'MULTIPLE') {
        op = (
          <input
            type='checkbox'
            key={uuidv4()}
            id={modifierId}
            name={modifierId}
            checked={this.state.modifiers_checked[option.id]}
            onChange={(e) => this.setModifier(e, option, 'multiple')}
          />
        );
      }

      var amount = [];
      if (option?.modifierData?.priceMoney && option?.modifierData?.priceMoney.amount > 0) {
        amount = (
          <div key={uuidv4()} className='text-color6 fw-semibold'>
            +
            <NumericFormat
              value={option.modifierData.priceMoney.amount / 100}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              fixedDecimalScale={true}
              decimalScale={2}
            />
          </div>
        );
      }

      inputs.push(
        <div className='option' key={uuidv4()}>
          <div className='row'>
            <div className='col-9'>
              {op}
              <label htmlFor={modifierId} className='text-color6 fw-semibold ms-2'>
                {option.modifierData.name}
              </label>
            </div>
            <div className='col-3 text-end'>{amount}</div>
          </div>
        </div>
      );
    });

    return (
      <div className='modifier' key={modifier.details.name}>
        <div
          className='bg-color7 p-3 text-color6 poppins fw-semibold mt-3 dropdown-toggle'
          style={{ cursor: 'pointer' }}
          data-bs-toggle='collapse'
          data-bs-target={'#modifier-' + this.state.product_to_add.catalogObjectId + '-' + modifier?.modifierListId}
        >
          {modifier.details.name}{' '}
          <span className='text-danger ms-5'>{modifier.details.selectionType === 'SINGLE' ? 'required' : ''}</span>
        </div>
        <div className='options px-3 pt-2 collapse' id={'modifier-' + this.state.product_to_add.catalogObjectId + '-' + modifier?.modifierListId}>
          {inputs}
        </div>
      </div>
    );
  }

  variations(variations) {
    if (variations.length > 1) {
      const inputs = [];

      variations.forEach((variation) => {
        // Default price
        let price = variation.itemVariationData.priceMoney.amount;

        // Check for location-specific overrides
        if (variation.itemVariationData.locationOverrides && variation.itemVariationData.locationOverrides.length > 0) {
          const locationOverride = variation.itemVariationData.locationOverrides.find(
            l => l.locationId === this.props?.location
          );

          if (locationOverride) {
            price = locationOverride?.priceMoney?.amount;
          }
        }

        inputs.push(
          <div className='option' key={variation.itemVariationData.name}>
            <div className="row">
              <div className="col-6">
                <input
                  type='radio'
                  id={this.state.product_to_add.catalogObjectId + "-" + variation.id}
                  name={variation.itemVariationData.itemId}
                  checked={this.state.variations_checked[variation.id] || false} // Set default value to false
                  onChange={((e) => this.setVariation(e))}
                />
                <label htmlFor={this.state.product_to_add.catalogObjectId + "-" + variation.id} className='text-color6 fw-semibold ms-2'>
                  {variation.itemVariationData.name}
                </label>
              </div>
              <div className="col-6 text-end">
                <NumericFormat className='text-color6 fw-semibold' value={price / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} />
              </div>
            </div>
          </div>
        );

      });

      return (
        <div className='modifier'>
          <div className="bg-color7 p-3 text-color6 poppins fw-semibold mt-3">
            Variations
          </div>
          <div className='options px-3 pt-2'>
            {inputs}
          </div>
        </div>
      );
    }
  }


  addItemToCart = () => {
    this.props?.addItemToCart(this.state);
    this.setState({
      modifiers_total: 0,
      variations_required: (this.props?.product?.itemData?.variations?.length > 1),
      modifiers_checked: {},
      variations_checked: {},
      product_to_add: {
        "catalogObjectId": this.props?.product?.itemData?.variations[0]?.id,
        "quantity": 1,
        "modifiers": [],
        "notes": ""
      },
      total: (this.props?.product?.itemData?.variations?.length > 1) ? 0 : this.props?.product?.itemData?.variations[0]?.itemVariationData?.priceMoney?.amount
    });
  }

  setVariation(e) {
    const variationId = e.target.id.split("-")[1];
    const variations_checked = { ...this.state.variations_checked };

    this.props?.product?.itemData?.variations?.forEach((variation) => {
      variations_checked[variation.id] = variation.id === variationId;
    });

    const selectedVariation = this.props?.product?.itemData?.variations?.find((variation) => variation.id === variationId);

    this.setState((prevState) => ({
      product_to_add: {
        ...prevState.product_to_add,
        catalogObjectId: selectedVariation?.id,
      },
      total: selectedVariation?.itemVariationData?.priceMoney?.amount,
      variations_checked,
      variations_required: false,
    }));
  }



  setModifier(e, option, type) {
    const modifiers = [...this.state.product_to_add.modifiers];
    const modifiersChecked = { ...this.state.modifiers_checked };

    if (e.target.checked) {
      if (type === 'multiple') {
        modifiers.push({
          catalogObjectId: option.id,
          modifierListId: option.modifierListId,
          quantity: '1'
        });
      } else {
        if (type === 'single') {
          const modifierGroup = this.props.product.itemData.modifierListInfo.find(
            (m) => m.modifierListId === option.modifierData.modifierListId
          );

          if (modifierGroup) {
            // Clear previously selected options in the same group
            modifierGroup.details.modifiers.forEach((mod) => {
              modifiersChecked[mod.id] = false;
              modifiers.forEach((existingModifier, i) => {
                if (existingModifier.catalogObjectId === mod.id) {
                  modifiers.splice(i, 1);
                }
              });
            });
          }
        }

        modifiers.push({
          catalogObjectId: option.id,
          modifierListId: option.modifierData.modifierListId,
          quantity: '1'
        });
      }
    } else {
      modifiers.forEach((modifier, i) => {
        if (option.id === modifier.catalogObjectId) {
          modifiers.splice(i, 1);
        }
      });
    }

    modifiersChecked[option.id] = e.target.checked;

    let amount = 0;
    modifiers.forEach((element) => {
      this.props.product.itemData.modifierListInfo.forEach((m) => {
        m.details.modifiers.forEach((mod) => {
          if (element.catalogObjectId === mod.id) {
            amount += parseInt(mod.modifierData.priceMoney.amount);
          }
        });
      });
    });

    this.setState({
      product_to_add: {
        catalogObjectId: this.state.product_to_add.catalogObjectId,
        quantity: this.state.product_to_add.quantity,
        modifiers: modifiers
      },
      modifiers_total: amount,
      modifiers_checked: modifiersChecked
    });
  }




  setNotes(e) {
    this.setState({
      product_to_add: {
        "catalogObjectId": this.state?.product_to_add?.catalogObjectId,
        "quantity": this.state?.product_to_add?.quantity,
        "modifiers": this.state?.product_to_add?.modifiers,
        "notes": e?.target?.value
      }
    })
  }

  setQuantity(quantity) {
    this.setState({
      product_to_add: {
        "catalogObjectId": this.state?.product_to_add?.catalogObjectId,
        "quantity": quantity,
        "modifiers": this.state?.product_to_add?.modifiers,
        "notes": this.state?.product_to_add?.notes
      }
    })
  }

}

export default Modal;