import React from 'react';
import './assets/CategoriesList.css';

class CategoriesList extends React.Component {
    render(){
        let categories = [];
        this.props?.catalog.forEach(category =>
            categories.push(
                <li className='poppins my-2' key={category?.categoryData?.name}><a href={"#" + category.id} className='text-color6 category-link'><strong>{category?.categoryData?.name}</strong></a></li>
            )
        );
        return (
            <ul className='mt-5 text-end category-list sticky-top'>
                {categories}
            </ul>
        )
    }
}

export default CategoriesList;