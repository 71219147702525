// SquarePaymentsScript.js
import React from 'react';

class SquarePaymentsScript extends React.Component {
  componentDidMount() {
    // Dynamically create the script tag for Square Payments
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_PROD === 'false' ? `https://sandbox.web.squarecdn.com/v1/square.js` : `https://web.squarecdn.com/v1/square.js`
    script.async = true;
    document.head.appendChild(script);
  }

  render() {
    return null; // This component doesn't render anything, it just loads the script
  }
}

export default SquarePaymentsScript;
